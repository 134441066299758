import * as styledComponents from 'styled-components';
export interface ThemeInterface {
  primaryColor: string;
  primaryColorInverted: string;
}

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export {css, keyframes, ThemeProvider};
export {colors} from './colors';
export {media} from './media';
export {GlobalStyles} from './globalStyles';
export default styled;
