import React from 'react';
import {useSpring, animated, config} from '@react-spring/web';
import styled, {media, colors} from '../../theme';
import Link from '../Link';
import {useHover, useLocation} from '../../hooks';

export interface MenuLinkProps {
  href: string;
  text: string;
}

const MenuLinkContainer = styled.li.attrs(() => ({
  role: 'presentation',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  list-style: none;
  margin: 0 20px;
  width: 100px;
  height: auto;
  ${media.largerThanPhone`
    height: 80%;
    align-items: center;
  `}
`;

const StyledMenuLink = styled(Link).attrs(() => ({
  role: 'menuitem',
}))`
  color: ${colors.WHITE};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 0;
  font-size: 1rem;
`;

const MenuItemContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-auto-flow: column;
`;

const AnimatedLinkContainer = animated(MenuLinkContainer);

export default function MenuLink({href, text}: MenuLinkProps): JSX.Element {
  const {location} = useLocation();
  const {pathname} = location;
  const [isHovering, hoverProps] = useHover();

  const isActive = pathname.includes(text.toLocaleLowerCase()) || (pathname === '/' && text === 'Home');

  const shouldScale = isHovering && !isActive;

  const {scale} = useSpring({
    config: config.stiff,
    scale: shouldScale ? [1.2, 1.2, 1.2] : [1, 1, 1],
  });

  return (
    <MenuItemContainer>
      <AnimatedLinkContainer
        {...hoverProps}
        {...(isActive
          ? {
              style: {
                transform: 'scale3d(1.2, 1.2, 1.2)',
              },
            }
          : {
              style: {
                transform: scale.to((x, y, z) => `scale3d(${x}, ${y}, ${z})`),
              },
            })}
      >
        <StyledMenuLink href={href}>{text}</StyledMenuLink>
      </AnimatedLinkContainer>
    </MenuItemContainer>
  );
}
