import {useEffect} from 'react';

interface ServiceWorkerMessage {
  url: string;
  msg: string;
}

const CLIENT_ID = 'CLIENT_ID';

/** whether or not the window object is
 * 1. available;
 * 2. has needed properties.
 * a guard check in compile time for undefined `window` error.
 */
const isClient =
  typeof window !== 'undefined' &&
  window.sessionStorage &&
  window.navigator &&
  window.location &&
  window.navigator.serviceWorker;

export default function useServiceWorker() {
  useEffect(() => {
    if (!isClient) return;
    const {navigator, sessionStorage} = window;
    navigator.serviceWorker.addEventListener('message', (event) => {
      /**
       * saving the data from the event to session storage
       */
      if (event && event.data)
        sessionStorage.setItem(CLIENT_ID, JSON.stringify(event.data, null, 2));
    });
    // return () => navigator.serviceWorker.removeEventListener('message'. e);
  }, []);

  if (isClient) {
    const item: string | null = sessionStorage.getItem(CLIENT_ID);
    if (item) {
      const serializedItem: ServiceWorkerMessage = JSON.parse(item);
      const {url, msg} = serializedItem;
      const dataLayer = window.dataLayer || [];
      /**
       * we don't want to send repeated page views.
       */
      if (url !== location.href) {
        /**
         * if we've already saved the item with {url: string, clientId: 'string'} from the url that has been visited.
         */
        const urlsInLayer = Object.values(dataLayer)
          .filter((item) => item.url)
          .map((item) => item.url);

        if (!urlsInLayer.includes(url)) {
          /**
           * if the item is not in cache, send it to data layer.
           */
          return dataLayer.push({url, msg});
        }
      }
    }
  }
}
