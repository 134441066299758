import React from 'react';
import Helmet from 'react-helmet';

interface SeoProps {
  description?: string;
  lang?: string;
  keywords?: string[];
  title?: string;
  meta?: ConcatArray<{
    name?: undefined;
    content: string | number;
    property: string;
  }>;
}

export default function Meta({
  description = 'Andrey Morozov personal website. @vanilladre',
  lang = 'en',
  keywords,
  title,
}: SeoProps): JSX.Element {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@dredotdev`,
        },
        {
          name: `twitter:creator`,
          content: '@dredotdev',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(
        keywords && keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : [],
      )}
    />
  );
}
