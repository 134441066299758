import {useState, useEffect} from 'react';

const isClient = typeof window === 'object';

export default function useSessionStorage<T>(
  key: string,
  initialValue?: T,
  raw?: boolean,
): [T, (value: T) => void] {
  if (!isClient) {
    return [initialValue as T, () => {}];
  }
  const [state, setState] = useState<T>(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));
        return initialValue;
      } else {
        return raw ? sessionStorageValue : JSON.parse(sessionStorageValue || 'null');
      }
    } catch {
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state);
      sessionStorage.setItem(key, serializedState);
    } catch {
      // noop
    }
  });

  return [state, setState];
}
