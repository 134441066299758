import React from 'react';
import MobileMenu from './MobileMenu';
import {MenuLinksProps} from './MenuLinks';
import DesktopMenu from './DesktopMenu';
import {useWindowSize} from '../../hooks';
import styled, {media} from '../../theme';
import {menuLinksData} from './menuLinksData';

const Menu = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: calc(100vw - 40px);
  padding: 0 20px;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
  ${media.largerThanPhone`
    background: transparent;
    justify-content: center;
    width: calc(100vw - 160px);
    height: 80;
    padding: 0 80px;
  `}
`;

interface RenderMenuProps {
  isMobile: boolean;
  menuLinks: MenuLinksProps['menuLinks'];
}
function renderMenuContent({
  isMobile,
  menuLinks,
}: RenderMenuProps): JSX.Element {
  return isMobile ? (
    <MobileMenu menuLinks={menuLinks} />
  ) : (
    <DesktopMenu menuLinks={menuLinks} />
  );
}

export default function NavigationMenu(): JSX.Element {
  const {width} = useWindowSize();
  const isMobile = width < 600;
  return <Menu>{renderMenuContent({isMobile, menuLinks: menuLinksData})}</Menu>;
}
