import React from 'react';
import MenuLink, {MenuLinkProps} from './MenuLink';

export interface MenuLinksProps {
  menuLinks: {
    id: number;
    href: string;
    text: string;
  }[];
}

export default function MenuLinks({menuLinks}: MenuLinksProps): JSX.Element {
  return (
    <>
      {menuLinks.map((menuLink: MenuLinkProps, id: number) => (
        <MenuLink key={id} {...menuLink} />
      ))}
    </>
  );
}
