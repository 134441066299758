import {useState} from 'react';

export interface UseHoverOptions {
  mouseEnterDelayMS?: number;
  mouseLeaveDelayMS?: number;
  accessible?: boolean;
}

export type HoverProps = Pick<
  React.HTMLAttributes<HTMLElement>,
  'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onBlur'
>;

export default function useHover({
  mouseEnterDelayMS = 0,
  mouseLeaveDelayMS = 0,
  accessible = true,
}: UseHoverOptions = {}): [boolean, HoverProps] {
  const [isHovering, setIsHovering] = useState(false);

  let mouseEnterTimer: number | undefined;
  let mouseOutTimer: number | undefined;

  const hoverProps = (): HoverProps => {
    const onMouseEnter = (): void => {
      clearTimeout(mouseOutTimer);
      mouseEnterTimer = setTimeout(() => setIsHovering(true), mouseEnterDelayMS);
    };

    const onMouseLeave = (): void => {
      clearTimeout(mouseEnterTimer);
      mouseOutTimer = setTimeout(() => setIsHovering(false), mouseLeaveDelayMS);
    };

    return {
      onMouseEnter,
      onMouseLeave,
      ...(accessible && {onBlur: onMouseLeave}),
      ...(accessible && {onFocus: onMouseEnter}),
    };
  };

  return [isHovering, hoverProps()];
}
