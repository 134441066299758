import React, {useState} from 'react';
import {useSpring, useChain, animated, useSpringRef, config} from '@react-spring/web';

import {useSessionStorage} from '../hooks';

import styled, {colors, media} from '../theme';

export const VISITED = '@@screen-loader/visited';

const Cover = styled(animated.div)`
  display: none;
  visibility: hidden;
  z-index: -1;
  pointer-events: none;
  will-change: opacity, z-index;
  ${media.largerThanPhone`
    display: block;
    height: 100vh;
    width: 100%;
    background: ${colors.WHITE};
    position: absolute;
    z-index: 10;
    visibility: visible;
  `}
`;

const CounterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  color: ${colors.BLACK};
`;

const Counter = styled(animated.span)`
  color: ${colors.BLACK};
`;

export default function ScreenLoader(): JSX.Element | null {
  const [counterAnimationFinished, setCounterAnimationFinished] = useState(false);
  const [value, setValue] = useSessionStorage(VISITED, false);

  const counterRef = useSpringRef();
  const counterProps = useSpring({
    config: config.slow,
    ref: counterRef,
    from: {number: 0},
    to: async (next) => {
      await next({
        number: 100,
      });
    },
    onRest: () => setCounterAnimationFinished(true),
  });

  const coverRef = useSpringRef();
  const coverSpring = useSpring({
    ref: coverRef,
    config: config.slow,
    from: {
      zIndex: 10000000,
      opacity: 1,
    },
    to: async (next) => {
      await next({
        zIndex: counterAnimationFinished ? 0 : 10000000,
        opacity: counterAnimationFinished ? 0 : 1,
      });
    },
    onRest: () => counterAnimationFinished && setValue(true),
  });

  useChain([coverRef, counterRef], [0, 0.5]);

  if (value) return null;

  return (
    <Cover style={coverSpring} aria-hidden={true}>
      <CounterContainer>
        <Counter>{counterProps.number.interpolate((x: number) => x.toFixed(0))}</Counter>%
      </CounterContainer>
    </Cover>
  );
}
