import {useState, useEffect} from 'react';

import {throttle} from './utils';

export interface Props {
  children: (renderProps: State) => JSX.Element;
}

export interface State {
  width: number;
  height: number;
}

const events = new Set();
const onResize = (): void => events.forEach((fn) => fn());
const isClient = typeof window !== 'undefined';

const subscriber = {
  subscribe: (handler: () => void) => {
    if (events.size === 0) {
      window.addEventListener('resize', onResize);
    }
    events.add(handler);
    const subscription = {
      unsubscribe: () => {
        events.delete(handler);
        if (events.size === 0) {
          window.removeEventListener('resize', onResize);
        }
      },
    };
    return subscription;
  },
};

export default function useWindowSize(initialWidth = 0, initialHeight = 0): State {
  const [size, setSize] = useState({
    width: isClient ? window.innerWidth : initialWidth,
    height: isClient ? window.innerHeight : initialHeight,
  });

  useEffect(() => {
    const subscription = subscriber.subscribe(() =>
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    );
    return () => {
      subscription.unsubscribe();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return size;
}
