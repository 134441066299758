import React, {SyntheticEvent} from 'react';
import {Link as GatsbyLink} from 'gatsby';

import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';

export interface LinkProps {
  children?: JSX.Element[] | JSX.Element | string;
  href: string;
  style?: object;
  activeStyle?: object;
  ariaLabel?: string;
  className?: string;
  onClick?: (event: SyntheticEvent<Element>) => void;
  onMouseEnter?: (event: SyntheticEvent<Element>) => void;
  onMouseLeave?: (event: SyntheticEvent<Element>) => void;
  onKeyPress?: (event: SyntheticEvent<Element>) => void;
  onFocus?: (event: SyntheticEvent<Element>) => void;
  onBlur?: (event: SyntheticEvent<Element>) => void;
}

export default function Link({
  href,
  className,
  children,
  ...props
}: LinkProps): JSX.Element {
  const internal = /^\/(?!\/)/.test(href);
  if (internal) {
    return (
      <GatsbyLink className={className} to={href} {...props}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <ExternalLink className={className} href={href} {...props}>
      {children}
    </ExternalLink>
  );
}
