import React, {Fragment, useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {useSessionStorage, useWindowSize} from '../hooks';
import ScreenLoader, {VISITED} from '../components/ScreenLoader';
import NavigationMenu from '../components/Menu';
import Meta from '../components/Meta';
import {GlobalStyles} from '../theme';
import {useForceUpdate} from '../hooks';

type LayoutProps = React.PropsWithChildren<{}>;

const seoKeywords = [
  'Andrey Morozov',
  'software engineer',
  'software',
  'javascript',
  'typescript',
  'node',
  'docker',
  'development',
  'frontend',
  'devops',
  'accessibility',
];

export const detailsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export default function Layout({children}: LayoutProps): JSX.Element {
  const [isLoadingFinished, setLoadingFinished] = useState(false);
  const [value] = useSessionStorage(VISITED);
  const data = useStaticQuery(detailsQuery);
  const update = useForceUpdate();

  const {title, description} = data.site.siteMetadata;

  useEffect(() => {
    setLoadingFinished(true);
    update();
    return () => setLoadingFinished(false);
  }, [value]);

  return (
    <Fragment>
      <Meta title={title} description={description} keywords={seoKeywords} />
      {/** if the client is on mobile or have visited app within current session - don't render screen loader. */}
      {!value ? <ScreenLoader /> : null}
      <GlobalStyles />
      {isLoadingFinished ? <NavigationMenu /> : null}
      {isLoadingFinished ? <main>{children}</main> : null}
    </Fragment>
  );
}
