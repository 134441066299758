import React, {useState, useEffect} from 'react';
import usePortal from 'react-useportal';
import {useLocation} from '../../hooks';
import styled from '../../theme';
import MenuLinks, {MenuLinksProps} from './MenuLinks';
import {useSpring, useTransition, animated, config, SpringValue} from '@react-spring/web';

const MobileMenuToggleContainer = styled.button`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  border: none;
  background: transparent;
  z-index: 6;
`;

const StyledMobileMenuContainer = styled.ul`
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  z-index: 5;
  top: 0;
  padding: 0;
  margin: 0;
  will-change: opacity;
  padding: 100px 0 0 0;
  background: #355c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #c06c84, #6c5b7b, #355c7d); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #c06c84,
    #6c5b7b,
    #355c7d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

const Svg = styled.svg`
  width: 25px;
  height: 25px;
  background-color: transparent;
`;

const strokeProps = {
  stroke: 'white',
  strokeWidth: '10%',
};

const AnimatedSvg = animated(Svg);
const AnimatedMenuContainer = animated(StyledMobileMenuContainer);

interface MobileHamburgerProps {
  onClick: (e: React.SyntheticEvent) => void;
  isOpen: boolean;
}
function MobileHamburger({onClick, isOpen}: MobileHamburgerProps): JSX.Element {
  const {topX1, topY1, topX2, topY2, midX1, midY1, midX2, midY2, botX1, botY1, botX2, botY2} = useSpring({
    topX1: isOpen ? 15 : 5,
    topY1: isOpen ? 85 : 25,
    topX2: isOpen ? 85 : 95,
    topY2: isOpen ? 15 : 25,
    midX1: isOpen ? 50 : 5,
    midY1: isOpen ? 50 : 50,
    midX2: isOpen ? 50 : 95,
    midY2: isOpen ? 50 : 50,
    botX1: isOpen ? 15 : 5,
    botY1: isOpen ? 15 : 75,
    botX2: isOpen ? 85 : 95,
    botY2: isOpen ? 85 : 75,
    config: config.stiff,
  });

  const inter = (val: SpringValue<number>) => val.to((x: number) => `${x}%`);

  return (
    <MobileMenuToggleContainer
      aria-label={`Press enter to ${isOpen ? 'close' : 'open'} the menu`}
      aria-haspopup={true}
      aria-expanded={isOpen ? true : false}
    >
      <AnimatedSvg onClick={onClick}>
        <animated.g>
          <animated.line x1={inter(topX1)} x2={inter(topX2)} y1={inter(topY1)} y2={inter(topY2)} {...strokeProps} />
          <animated.line x1={inter(midX1)} x2={inter(midX2)} y1={inter(midY1)} y2={inter(midY2)} {...strokeProps} />
          <animated.line x1={inter(botX1)} x2={inter(botX2)} y1={inter(botY1)} y2={inter(botY2)} {...strokeProps} />
        </animated.g>
      </AnimatedSvg>
    </MobileMenuToggleContainer>
  );
}

type MobileMenuContainerProps = React.PropsWithChildren<{
  isOpen: boolean;
}>;
function MobileMenuContainer({isOpen, children}: MobileMenuContainerProps) {
  const transition = useTransition(isOpen, {
    from: {x: 100},
    enter: {x: 0},
    leave: {x: -100},
  });

  return transition(
    ({x}, item) =>
      item && (
        <AnimatedMenuContainer
          role="menu"
          aria-hidden={isOpen ? false : true}
          isOpen={isOpen}
          style={{transform: x.to((x) => `translateX(${x}%)`)}}
        >
          {children}
        </AnimatedMenuContainer>
      ),
  );
}

export default function MobileMenu({menuLinks}: MenuLinksProps) {
  const {location} = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const {openPortal, closePortal, isOpen, Portal} = usePortal({
    bindTo: document && (document.getElementById('gatsby-focus-wrapper') as HTMLElement),
  });

  useEffect(() => {
    if (!document && typeof document !== 'undefined') return;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  useEffect(() => {
    if (currentPath !== location.pathname) {
      setCurrentPath(location.pathname);
      setTimeout(() => {
        closePortal();
      }, 500);
    }
  }, [location.pathname]);

  const handleClick = (e: React.SyntheticEvent) => {
    if (isOpen) {
      closePortal(e);
    } else {
      openPortal(e);
    }
  };

  return (
    <>
      <MobileHamburger onClick={handleClick} isOpen={isOpen} />
      <Portal>
        <MobileMenuContainer isOpen={isOpen}>
          <MenuLinks menuLinks={menuLinks} />
        </MobileMenuContainer>
      </Portal>
    </>
  );
}
