import {useEffect, useState} from 'react';
import {globalHistory, HistoryLocation, NavigateFn} from '@reach/router';

export interface UseLocationState {
  location: HistoryLocation;
  navigate: NavigateFn;
}

export default function useLocation(): UseLocationState {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      const {location} = params;
      const newState = Object.assign({}, initialState, {location});
      setState(newState);
    });
    return () => {
      removeListener();
    };
  }, [initialState]);
  return state;
}
