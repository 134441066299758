export const colors = {
  MAIN_BLUE: '#005b96',
  BLACK: '#000000',
  WHITE: '#ffffff',
  TRANSPARENT: '#00000000',
  BLUE_2U: 'rgb(21, 118, 212)',
  ORANGE_FETNER: 'rgb(255, 124, 28)',
  BLUE_HILTON_HONORS: '#009CDE',
  PLASTIQ_BLUE: 'rgb(29, 28, 88)',
  RIVIAN_YELLOW: 'rgb(255, 172, 0)',
};
