import React from 'react';

import {LinkProps} from './index';

export default function ExternalLink({href, ...props}: LinkProps): JSX.Element {
  const target =
    href && (href.startsWith('tel') || href.startsWith('mailto'))
      ? '_self'
      : '_blank';
  const {
    className,
    children,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    ariaLabel,
  } = props;
  return (
    <a
      className={className}
      href={href}
      target={target}
      rel="noopener noreferrer"
      aria-label={ariaLabel}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{...style}}
    >
      {children}
    </a>
  );
}
