export const menuLinksData = [
  {
    id: 1,
    href: '/',
    text: 'Home',
  },
  {
    id: 2,
    href: '/about/',
    text: 'About',
  },
  {
    id: 3,
    href: '/contact/',
    text: 'Contact',
  },
  // {
  //   id: 4,
  //   href: '/projects/',
  //   text: 'Projects',
  // },
];
