import React from 'react';
import styled from '../../theme';
import MenuLinks, {MenuLinksProps} from './MenuLinks';

const DesktopMenuContainer = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  position: relative;
  background: transparent;
  top: 0;
`;

export default function DesktopMenu({menuLinks}: MenuLinksProps): JSX.Element {
  return (
    <DesktopMenuContainer role="menu">
      <MenuLinks menuLinks={menuLinks} />
    </DesktopMenuContainer>
  );
}
