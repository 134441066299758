import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: #355C7D;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #C06C84, #6C5B7B, #355C7D);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #C06C84, #6C5B7B, #355C7D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 16px;
    height: 100%;
    width: 100%;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    min-height: 100vh;
    #gatsby-focus-wrapper {
      overflow: hidden;
    }
    h1 {
      font-weight: 800;
    }
    p {
      font-weight: 500;
    }
  };
  a,p,h1,h2,h3,h4,h5,h6,span,button,textarea {
    font-family: "Roboto", sans-serif;
    color: white;
  };
  input,select,textarea {
    color: black;
  };
  dd, dt {
    margin: 0;
  }
`;
