import {ThemedCssFunction} from 'styled-components';
import {css, ThemeInterface} from './index';

const sizes = {
  largerThanPhone: 600,
  largerThanTablet: 1100,
  largerThanDesktop: 1360,
};

export const media = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {} as {[key in keyof typeof sizes]: ThemedCssFunction<ThemeInterface>});
